@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-text {
  animation: slideInFromLeft 1s ease-out;
}
